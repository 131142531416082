import React, { useState, useEffect } from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type Props = {
  fecha: any;
  formato:string | null;
  getDate: (date: Date) => void;
};

const DatePickerComponente: React.FC<Props> = ({ fecha,formato, getDate }) => {
  const [fechaSelect, setFechaSelect] = useState<Value>(new Date());

  useEffect(() => {
    if (fecha && fecha.value) {
      const dateValue = new Date(fecha.value);
      if (!isNaN(dateValue.getTime())) {
        setFechaSelect(dateValue);
      }
    }
  }, [fecha]);

  const onChange = (value: Value) => {
    setFechaSelect(value);
    if (value instanceof Date && !isNaN(value.getTime())) {
      getDate(value);
    }
  };

  return (
    <>
      <DatePicker
        onChange={onChange}
        value={fechaSelect}
        className={formato || "form-control form-control-lg form-control-solid"}
        format="dd/MM/yyyy"
      />
    </>
  );
};

export { DatePickerComponente };

import { Html5QrcodeScanner } from "html5-qrcode";
import { Html5QrcodeError, Html5QrcodeResult } from "html5-qrcode/esm/core";
import { useCallback, useEffect, useRef, useState, FC } from "react";

const MenuTestPage: FC = () => {
  const barcodeScannerContainer = useRef<HTMLDivElement | null>(null);
  const barcodeScanner = useRef<Html5QrcodeScanner | null>(null);
  const [result, setResult] = useState<string[]>([]);

  const onScanSuccess = useCallback(
    (decodedText: string, result: Html5QrcodeResult) => {
      setResult(prevResult => [...prevResult, decodedText]);
    },
    []
  );

  const onScanError = (errorMessage: string, error: Html5QrcodeError) => {
    // Maneja los errores aquí si es necesario
  };

  useEffect(() => {
    if (barcodeScannerContainer.current) {
      barcodeScanner.current = new Html5QrcodeScanner(
        "qr-reader",
        {
          fps: 10,
          qrbox: 250,
        },
        true
      );
      barcodeScanner.current.render(onScanSuccess, onScanError);
    }
  }, [onScanSuccess]); // Agregamos onScanSuccess a la lista de dependencias

  return (
    <div className="row">
      <div className="col-2">
        <div id="qr-reader" ref={barcodeScannerContainer}>
          Barcode Scanner
        </div>
      </div>
      <div className="col-10">
        <ul>
          {result.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { MenuTestPage };

import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import { HeaderUserMenu,  ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import {mediaS3} from '../../../../helpers/imagenS3'

const itemClass = 'ms-1 ms-lg-3'
//onst btnClass ='btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'
interface Avatar {
  path: string;
  format: string;
  size: number;
  id: string;
}

const Navbar = () => {
  const {config} = useLayout()
  const {currentUser} = useAuth()
  const targetSize = 600;
  const targetFormat = "webp";
  let avatar = '/media/avatars/300-1.jpg';
  if (currentUser?.avatar) {
    const imagenRows: Avatar[] = JSON.parse(currentUser.avatar);
    
    // Finding the matching avatar
    const matchingAvatar = imagenRows.find(element => 
      element.format === targetFormat && element.size === targetSize
    );

    if (matchingAvatar) {
      avatar = mediaS3(matchingAvatar.path);
    }
   
  }
  
  return (
    <div className='app-navbar flex-shrink-0'>
      

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={avatar} alt={currentUser?.nombre} />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}

import React, { FC, useState } from 'react';
import { KTIcon } from './components/KTIcon';

type Props = {
  text: string | undefined;
};

const CopyToClipboardComponente: FC<Props> = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async (text: string | undefined) => {
    if (!text) {
      console.warn('No hay texto para copiar');
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  return (
    <>
      <button onClick={() => copyToClipboard(text)} className='btn position-absolute top-0 end-0'><KTIcon iconName='maximize' className=' fs-1' /></button>
      {copied && <span >.</span>}
    </>
  )
}

export { CopyToClipboardComponente };

/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
//import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useMenu} from '../../../../../hooks/useSession'


interface MenuItem {
  id_modulo: number
  nombre: string
  icono?: string
  paginas?: any[]
  submenu?: any[]
  id_seccion?:number
  orden?:number|null
}

interface PageItem {
  id_modulo: number
  nombre_modulo: string
  icono?: string
  link:string;
  orden?:number|null
}

const menuUsuario = (menu:any[]) => {
  const generateMenu = (menuItems: MenuItem[] | undefined) => {
    menuItems?.sort((a, b) => (a?.orden || 0) - (b?.orden || 0));
    return menuItems?.map((item: MenuItem) => (
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title={item.nombre}
        fontIcon='bi-archive'
        icon={item.icono?item.icono:'element-plus'}
        key={'menu-'+item.id_seccion}
      >
        {generatePages(item.paginas)}
        {generateMenu(item.submenu)}
      </SidebarMenuItemWithSub>
    ));
  };

  

  const generatePages = (menuPages: PageItem[] | undefined) => {
    menuPages?.sort((a, b) => (a?.orden || 0) - (b?.orden || 0));
    return menuPages?.map((item: PageItem) => (
      <SidebarMenuItem
        title={item.nombre_modulo}
        hasBullet={item.icono?false:true}
        to={item.link}
        icon={item.icono}
        key={item.id_modulo}
      />
    ));
  };

  return <>{generateMenu(menu)}</>;

};

const SidebarMenuMain = () => {
  const rolesMenu = useMenu();
  return (
    <>
    {rolesMenu?.data !== undefined && rolesMenu?.data.length > 0 ? (
      menuUsuario(rolesMenu.data)
    ) : (
      <p>Aun no te hemos asignado Permisos.</p>
    )}
  </>
  )
}

export {SidebarMenuMain}

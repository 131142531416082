
import { useQuery } from 'react-query';
import {getMenu} from '../requests/seccionesRequest'




export const useMenu = () =>{
    const rolesQuery = useQuery(
        ['menu-general'],
        () => getMenu(),
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    );
    return rolesQuery;
}




import React, {useState, useEffect} from 'react'
import {DateRangePicker} from 'react-date-range'
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  endOfMonth,
  endOfYear,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay,
} from 'date-fns'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // ztheme css file
import {es} from 'date-fns/locale'

type Props = {
  getDRPicker: (data: any) => void
}

const DRPicker: React.FC<Props> = ({getDRPicker}) => {
  

  const [datePicker, setDatePicker] = useState<any>([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ])

  useEffect(() => {
        const [dateRange] = datePicker;
        getDRPicker(dateRange);
  }, [datePicker,getDRPicker])

  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfTomorrow: startOfDay(addDays(new Date(), +1)),
    endOfTomorrow: endOfDay(addDays(new Date(), +1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  }

  return (
    <>
      <DateRangePicker
        onChange={(item) => setDatePicker([item.selection])}
        locale={es}
        moveRangeOnFirstSelection={false}
        months={1}
        direction='horizontal'
        ranges={datePicker}
        inputRanges={[]}
        staticRanges={[
          {
            label: 'Hoy',
            range: () => ({
              startDate: defineds.startOfToday,
              endDate: defineds.endOfToday,
            }),
            isSelected(range) {
              const definedRange = this.range()
              if (
                range.startDate &&
                definedRange.startDate &&
                range.endDate &&
                definedRange.endDate
              ) {
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                )
              }
              return false
            },
          },
          {
            label: 'Ayer',
            range: () => ({
              startDate: defineds.startOfYesterday,
              endDate: defineds.endOfYesterday,
            }),
            isSelected(range) {
              const definedRange = this.range()
              if (
                range.startDate &&
                definedRange.startDate &&
                range.endDate &&
                definedRange.endDate
              ) {
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                )
              }
              return false
            },
          },
          {
            label: 'Mañana',
            range: () => ({
              startDate: defineds.startOfTomorrow,
              endDate: defineds.endOfTomorrow,
            }),
            isSelected(range) {
              const definedRange = this.range()
              if (
                range.startDate &&
                definedRange.startDate &&
                range.endDate &&
                definedRange.endDate
              ) {
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                )
              }
              return false
            },
          },
          {
            label: 'Esta semana',
            range: () => ({
              startDate: defineds.startOfWeek,
              endDate: defineds.endOfWeek,
            }),
            isSelected(range) {
              const definedRange = this.range()
              if (
                range.startDate &&
                definedRange.startDate &&
                range.endDate &&
                definedRange.endDate
              ) {
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                )
              }
              return false
            },
          },
          {
            label: 'Semana Pasada',
            range: () => ({
              startDate: defineds.startOfLastWeek,
              endDate: defineds.endOfLastWeek,
            }),
            isSelected(range) {
              const definedRange = this.range()
              if (
                range.startDate &&
                definedRange.startDate &&
                range.endDate &&
                definedRange.endDate
              ) {
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                )
              }
              return false
            },
          },
          {
            label: 'Este Mes',
            range: () => ({
              startDate: defineds.startOfMonth,
              endDate: defineds.endOfMonth,
            }),
            isSelected(range) {
              const definedRange = this.range()
              if (
                range.startDate &&
                definedRange.startDate &&
                range.endDate &&
                definedRange.endDate
              ) {
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                )
              }
              return false
            },
          },
          {
            label: 'Mes pasado',
            range: () => ({
              startDate: defineds.startOfLastMonth,
              endDate: defineds.endOfLastMonth,
            }),
            isSelected(range) {
              const definedRange = this.range()
              if (
                range.startDate &&
                definedRange.startDate &&
                range.endDate &&
                definedRange.endDate
              ) {
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                )
              }
              return false
            },
          },
          {
            label: 'Año pasado',
            range: () => ({
              startDate: startOfYear(addYears(new Date(), -1)),
              endDate: endOfYear(addYears(new Date(), -1)),
            }),
            isSelected(range) {
              const definedRange = this.range()
              if (
                range.startDate &&
                definedRange.startDate &&
                range.endDate &&
                definedRange.endDate
              ) {
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                )
              }
              return false
            },
          },
          {
            label: 'Este Año',
            range: () => ({
              startDate: startOfYear(new Date()),
              endDate: endOfYear(new Date()),
            }),
            isSelected(range) {
              const definedRange = this.range()
              if (
                range.startDate &&
                definedRange.startDate &&
                range.endDate &&
                definedRange.endDate
              ) {
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                )
              }
              return false
            },
          },
        ]}
      />
    </>
  )
}

export {DRPicker}

import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const GET_SECCIONES = `${API_URL}/secciones`

export const getMenu = async() => {
  return await axios
    .get(`${GET_SECCIONES}/menu_personalizado`)
    .then((d: AxiosResponse<any>) => d.data)
}


